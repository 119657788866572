import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Caption = makeShortcode("Caption");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ResourceCard = makeShortcode("ResourceCard");
const MdxIcon = makeShortcode("MdxIcon");
const DoDontExample = makeShortcode("DoDontExample");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "this-header-is-part-of-the-carbon-ui-shell-a-shell-is-a-collection-of-components-shared-by-all-products-within-a-platform-it-provides-a-common-set-of-interaction-patterns-that-persist-between-and-across-products"
    }}>{`This header is part of the Carbon UI shell. A shell is a collection of components shared by all products within a platform. It provides a common set of interaction patterns that persist between and across products.`}</h3>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">General guidance</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Anatomy</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Icon placement</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Behavior</AnchorLink>
    </AnchorLinks>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "64.99999999999999%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAABaklEQVQ4y62TTW7CMBCFOVjvwc+CRXc9SX5YBbFFSNmHddU1La26opwALhACTpzYSaZvTBJCBFKlYukxYzPz8SYOvZfttveo9RQE5+TZ88Yj150PHGc6sO0Za+g4szrH+WzoulcaTSYmDixr2res+dC2xw35bb8PNkS0iiL6TlN6R2R9HI9GayHoS8orreOYPqFVGNIPel93u6ABktYL4lUUEp+K8lyp00lRWSqzv6EySZQWgnNpesFogGmW+XyWKaWLsiSZZSThVGlNvM9b4r0uCkrwPdch19wrksRvgFkFVABWkepVAtDWeZAChnRdYhIp5QWY57lfFWpuYmAb0I0MTOEQfQw2wDiOL0AU+PWv1cC2w27O8YjLOhwOxgSfJe2RbwG7o3YdY0R2dRe4qIASxbgLffd2WahXGLfey+oZLtrPcEn/XHC4vLw2adqHKw9gC3KEEE4URX+VBXlhGPbPLzXRw/7LzPoFDh3fGLLHMVgAAAAASUVORK5CYII=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "UI shell header",
        "title": "UI shell header",
        "src": "/static/46b80b88760a0d4b7869d63c6f07a3cc/fb070/zones-platform.png",
        "srcSet": ["/static/46b80b88760a0d4b7869d63c6f07a3cc/d6747/zones-platform.png 288w", "/static/46b80b88760a0d4b7869d63c6f07a3cc/09548/zones-platform.png 576w", "/static/46b80b88760a0d4b7869d63c6f07a3cc/fb070/zones-platform.png 1152w", "/static/46b80b88760a0d4b7869d63c6f07a3cc/fb104/zones-platform.png 1728w", "/static/46b80b88760a0d4b7869d63c6f07a3cc/8fefe/zones-platform.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">UI shell header</Caption>
    <h2 {...{
      "id": "resources"
    }}>{`Resources`}</h2>
    <Row className="resource-card-group" mdxType="Row">
  <Column colLg={4} colMd={4} noGutterSm mdxType="Column">
    <ResourceCard subTitle="UI Shell template" href="https://sketch.cloud/s/EjVmA" mdxType="ResourceCard">
      <MdxIcon name="sketch" mdxType="MdxIcon" />
    </ResourceCard>
  </Column>
    </Row>
    <h2 {...{
      "id": "general-guidance"
    }}>{`General guidance`}</h2>
    <p>{`The UI shell is made up of three components: The header, the `}<a parentName="p" {...{
        "href": "/components/UI-shell-left-panel/usage"
      }}>{`left panel`}</a>{`, and the `}<a parentName="p" {...{
        "href": "/components/UI-shell-right-panel/usage"
      }}>{`right panel`}</a>{`. All three can be used independently, but the components were designed to work together.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Shell UI component`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Header`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The highest level of navigation. The header can be used on its own for simple products or be used to trigger the left and right panels.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Left panel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An optional panel that is used for a product’s navigation.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Right panel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An optional panel that shows additional system level actions or content associated with a system icon in the header.`}</td>
        </tr>
      </tbody>
    </table>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "83.42391304347827%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAAsSAAALEgHS3X78AAACXklEQVQ4y5WUS2gTURSGbyaxRauJgTSTGScSLaWbklZUfIA7cavgxlAXLqwuXLsQSSQQSRdKFoK4sBjHEBCCWYhgKb4KCiKKpYiKIFiKIVNjYkLzmslc/5mmIbdJS3rg59xz5/Ddc+5jCNnEPo9cZOL5kUnSswmCYPH5fBw853a7OZ7nOZd7kPPyAndp71HrWWnc2je42yLyHvObISPPyPd6vZwkSb0vRind1ksewEw8BB2A/G0aa/pD0Pi6Ob/H4/GLojiGzvyA7WBowWDwZSwWo9FotBYOh7VQKNTSjbYx8rRIJKIFAgHN5XKpADbQrorWjzHAarX6hq5ag25iuq6bPp1OUwApqqTYT8MfZ4D5fP6Vqqq0Xq+rtVpNLxQKXZXL5XTk6YlEQsehNAwY1ACQrVBRlNfNIjQDXCwWuwpQs8pkMkkB1JvVNTpaRnILCGsBSqUSIwNoGCqkTqdTb7bcWSH2cEtAWZap3W7fGFipVDYEto+3AnzRBFaxhyoAXYXDU5GjAqgCWAdIA7DereUPtAdbuzapVIo6HA6Ke2heHegEA/z7r3BtpVh6XC6XH6A1GacuK9msrPxZfphd+v3IHGMuk8nIyJHvT0/LAMYlQZTdPB/fI4jDJmjGdaYFnSJkYCch1vVv9DIhfXCWjjd+erGfHk4OrMXnpYOEfBu9YgbLV+O2HyevO5cmbm9HR8YPgYMsdO67tRh7alu5N2OjudrqHDT78Z3l2dC5XbPDE45J6QhbxKd9F5j4690nZKGikIXnc8z8Fyw0//Y9+bn4i9yZusV8u7n/lOn/A+zvT3LmMoi9AAAAAElFTkSuQmCC')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "UI shell components.",
        "title": "UI shell components.",
        "src": "/static/be299d265c24f9028bcc0646d8bfd634/fb070/shell-usage-1.png",
        "srcSet": ["/static/be299d265c24f9028bcc0646d8bfd634/d6747/shell-usage-1.png 288w", "/static/be299d265c24f9028bcc0646d8bfd634/09548/shell-usage-1.png 576w", "/static/be299d265c24f9028bcc0646d8bfd634/fb070/shell-usage-1.png 1152w", "/static/be299d265c24f9028bcc0646d8bfd634/c3e47/shell-usage-1.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">UI shell components</Caption>
    <p>{`For each UI shell component, left-to-right translates to product-to-global. The left side of the header contains items relevant at the product level. Moving to the right along the header, the functions become more global. Elements in the middle of the header should represent system-level controls. Elements on the right side of the header, such as the switcher, are the most global in their scope and span multiple products.`}</p>
    <h2 {...{
      "id": "anatomy"
    }}>{`Anatomy`}</h2>
    <p>{`The header spans the full width of the viewport and is the topmost element in the browser window. Header elements are persistent throughout the product experience.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "23.392857142857142%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAAsSAAALEgHS3X78AAAA0klEQVQY062PTU4DMQyF3SYSLCqVBYonjp2fKW2h3XAEFr0RV8iUMKqEBAce3IEj1JL9nqWnTzbADavznQ7ybymnn91+90FEI4Xw6VWZebzugcOsIjJ670fn3IiIc1+95huLfGn+faYeuZy2qXy/vhwbBarPsb9ElnOMsYYQ6iblVji2XEpV6KCQQWGDQ6zo8Lzh1EpMl63kP+A0TTd7WTz9uwAGDoulI29hDQugtelTtnqZBb430N2Zff9kWdjqhdahs4SdhceVAQdLkAcDqKr1C626J9UGp+t6AAAAAElFTkSuQmCC')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Primary header",
        "title": "Primary header",
        "src": "/static/dd274f9f0f8a3db8b8085009d9cd6733/fb070/header-usage-2.png",
        "srcSet": ["/static/dd274f9f0f8a3db8b8085009d9cd6733/d6747/header-usage-2.png 288w", "/static/dd274f9f0f8a3db8b8085009d9cd6733/09548/header-usage-2.png 576w", "/static/dd274f9f0f8a3db8b8085009d9cd6733/fb070/header-usage-2.png 1152w", "/static/dd274f9f0f8a3db8b8085009d9cd6733/fb104/header-usage-2.png 1728w", "/static/dd274f9f0f8a3db8b8085009d9cd6733/8fefe/header-usage-2.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">Primary header</Caption>
    <h3 {...{
      "id": "header-elements"
    }}>{`Header elements`}</h3>
    <h4 {...{
      "id": "hamburger-menu"
    }}>{`Hamburger menu`}</h4>
    <p>{`The hamburger icon is used to open product navigation such as the `}<a parentName="p" {...{
        "href": "/components/UI-shell-left-panel/usage"
      }}>{`left panel.`}</a></p>
    <h4 {...{
      "id": "system-name"
    }}>{`System name`}</h4>
    <p>{`For IBM products, the system name is always preceded by “IBM.”`}</p>
    <h4 {...{
      "id": "primary-navigation"
    }}>{`Primary navigation`}</h4>
    <p>{`Links in the header are supported as product navigation, if required. These links drop down to the side menu in narrow screen widths.`}</p>
    <h4 {...{
      "id": "dropdown"
    }}>{`Dropdown`}</h4>
    <p>{`Dropdowns are supported as product navigation, if required. Include the down-pointing chevron after the link label. Dropdowns open on click and are closed by either selecting an item in the menu, clicking outside the menu area, or clicking on the menu label. When open, the chevron should point up. Dropdown menu labels serve only to open the dropdown; they cannot link to another page in the product.`}</p>
    <h4 {...{
      "id": "header-icons"
    }}>{`Header icons`}</h4>
    <p>{`These icons are reserved for universal, system-level functions such as profile, search, notifications, and similar functions. Not every product on a system is required to show the same header icons, but it is recommended for a better cross-product user experience.`}</p>
    <h4 {...{
      "id": "switcher"
    }}>{`Switcher`}</h4>
    <p>{`The switcher provides a way for the user to easily navigate `}<em parentName="p">{`between`}</em>{` products and systems. Recommended uses for this component include recently used apps, frequently used apps, or all apps attached to the user’s account. If the list is a manageable size, include all apps or products available on the system.`}</p>
    <p>{`Links to related systems should also live in the switcher. If you’re building a product for IBM, your switcher should include a link to `}<a parentName="p" {...{
        "href": "http://ibm.com/"
      }}>{`IBM.`}</a></p>
    <h2 {...{
      "id": "icon-placement"
    }}>{`Icon placement`}</h2>
    <p>{`Icons are not a required element of the header and a product may choose to use any set of icons for their UI. Icons should always be aligned to the right of the header with no gaps between icons.`}</p>
    <Row mdxType="Row">
      <Column colMd={4} colLg={4} mdxType="Column">
  <DoDontExample type="do" caption="Header icons are right aligned with no gaps" mdxType="DoDontExample">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAABC0lEQVQ4y+1Su2rEMBC8KPjdxe9H6eeJWHAkV13+xC6MC2NIk68PSuHNWBzqAwkc5BaWlRjtzCyrw+Ee/yC2bWNExIZhYK7rsjRNWRzHP82HnQv194xlWaYdvsDheZ5nYVnWEQCHEo+iSFXf97lt2zxJEm4Yhr47jsODIDiiPmOqJ+2QrjGOI3meRyAkACpBSkVRUNu2FIYh9X1PeZ5T13XUNI16u58h8nElfNSE0zRtUJN49AVA7glCCXVZVZUEoeSc77gEmazrWsL1Z1mWBKfvmhAjX8D3tq7rq2maPRoEAAEyVaEusCyBZoFRBZoFhAWmUWfUE0SDP12K+jbLsjAshQG4jW9zjxuOb6osbaF7UlrAAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Example of header icons right aligned with no gaps.",
              "title": "Example of header icons right aligned with no gaps.",
              "src": "/static/aaed46b347e8b5119b81919ec1250df9/fb070/header-usage-5.png",
              "srcSet": ["/static/aaed46b347e8b5119b81919ec1250df9/d6747/header-usage-5.png 288w", "/static/aaed46b347e8b5119b81919ec1250df9/09548/header-usage-5.png 576w", "/static/aaed46b347e8b5119b81919ec1250df9/fb070/header-usage-5.png 1152w", "/static/aaed46b347e8b5119b81919ec1250df9/97a97/header-usage-5.png 1470w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </DoDontExample>
      </Column>
      <Column colMd={4} colLg={4} mdxType="Column">
  <DoDontExample caption="Header icons with a gap between the account and help icons." mdxType="DoDontExample">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "99.8641304347826%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAABHUlEQVQ4y+2SW2uDQBCFdQUvISqKiVe8gELIYpeEUvrQ/lDBBwXxva/9YQWJMJ0dSt4LDQSaAdnDcuabM7iK8qh/UJfLhQEA67qOmabJkiRhYRj+9lMlC8+/C+Y4zjXhMyZ8GcfxrOs6x4QcJ/EgCDgmJi3vPc8jHUURx2auqiqP45hblvWE3lyyttutosBP9X0Pm80GEAj7/R7SNIXD4UC6bVvI85z0breDsizhdDqR5pzLnk8JrOuaXYHDMEjgglMXbKQTDaSPx+OSZRlphCxFUSxCiAWTfTVNA+j9kMCqqpiyrusb8t7neX41DEPgNIGrCTQLHEAa1xK+75PGlYXrukLTNNLoOaO3kkDbtm/zU+jZTNN0X8/mUXdc38pNckWtPTvZAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Example of the header icons with a gap between the account and help icons.",
              "title": "Example of the header icons with a gap between the account and help icons.",
              "src": "/static/906006e0d620d2667ab6e0d0de51fd44/fb070/header-usage-6.png",
              "srcSet": ["/static/906006e0d620d2667ab6e0d0de51fd44/d6747/header-usage-6.png 288w", "/static/906006e0d620d2667ab6e0d0de51fd44/09548/header-usage-6.png 576w", "/static/906006e0d620d2667ab6e0d0de51fd44/fb070/header-usage-6.png 1152w", "/static/906006e0d620d2667ab6e0d0de51fd44/c3e47/header-usage-6.png 1472w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </DoDontExample>
      </Column>
    </Row>
    <h3 {...{
      "id": "example-placement"
    }}>{`Example placement`}</h3>
    <p>{`The example below shows a header with a switcher, three core icons, a product icon, and search.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "28.260869565217394%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsSAAALEgHS3X78AAABFUlEQVQY02P4//8/w9KlSxlA4NOnT8yenp4s3NzcLOIS4ixKMnIsDOLMzAzCDEwRiqZsxYr2HAVKdhx6CqosDPwMTAycDBDAzYAJjh8/zkAVAHUhI9SF0UAX9gJd2CghIdEsJSPdBHRlu6qMQoe0vGyjvKxci6KcfIucgnyjmqzCBG0ZRROQPl0ZJUYUA5csWcIEYn/8+HGeu7v7Px4entdAL7+Xl5N7Lysr+05WTvadirLye3l5+fcKigrvVFVU3sjKyf2UlpGOAOmTkZVhIsr1F4UjeI8wmDGD2EdYvXlA9FwGXubDbN68cAfNOIBd8/fv3zGDw7aP+X/JL7B3/nvNBRv8f+5/hv9uU5lhaowZ+FD0AAB3h082TKyhLAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "icon placement.",
            "title": "icon placement.",
            "src": "/static/ab220138a74abe3f90ab30047b3b4f41/fb070/header-usage-4.png",
            "srcSet": ["/static/ab220138a74abe3f90ab30047b3b4f41/d6747/header-usage-4.png 288w", "/static/ab220138a74abe3f90ab30047b3b4f41/09548/header-usage-4.png 576w", "/static/ab220138a74abe3f90ab30047b3b4f41/fb070/header-usage-4.png 1152w", "/static/ab220138a74abe3f90ab30047b3b4f41/c3e47/header-usage-4.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Icon`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Position`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Search`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Left`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Search should always be positioned as the furthest left icon. This is to allow for a expanding search field that does not disrupt other icon positions.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Other`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Right of Search and left of core icons`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of header icons a product uses may vary. This placement will help avoid disrupting the position of the core icons and inconsistencies as your user navigates between other product shell headers.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Help`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4th from the right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Notifications`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3rd from the right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Account`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2nd from the right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Switcher`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The switcher should always be positioned as the furthest right icon. This ensures the icon does not shift when navigating across systems.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "behavior"
    }}>{`Behavior`}</h2>
    <p>{`As a header scales down to fit smaller screen sizes, header links and menus should collapse into a left-panel hamburger menu. See the examples below to better understand the header’s responsive behavior.`}</p>
    <p>{`If your UI includes a left panel, the header links should be added above the left panel items, pushing them down accordingly.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "93.66071428571429%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAAAsSAAALEgHS3X78AAACNUlEQVQ4y7WTy6tSURTGzz3VRAQf4FsRHAgKphNH/g0hVET0Z9RUaBZF9wZxqe6ZNBEbhi/CHDmWwsRZGvgAH/h+6/Ho7tvnHk9Fee+5xN3wY22Pa3977bW/zTAYPp+PCYfDR1arlXU4HKzZbJaj3W4Xo8vlYnU6HatSqVi1Wi1C5yaTiXU6nWIec20D6g/cbvcHo9H4FjtyBoOBQ1UcfsvRZrNxer2e02g0nFarFaFz5J5ZLJb3WPdUFozFYu9yuRxJp9Mkm82STCZDUqkUSSQSJJlM/hHj8fhf0PxoNFqTBQkhL8n5WIDNFVnThdvt9pss2Ol0TujHzWbDC4JAIxmPx2Q0GilBWK1WpNvtlmRBLD6RKuR3ux2hzGYzMplMyHQ6vQyBFjAYDH4Jrtdr8cgQWkiiPBJ5bMRD9DJWi8WC9Pv9gizI8/wZ+c+BE9V/v5Q7qOoN4jPw/B+8uAD6/yvwGFyTsf1+/5NgMPgjFAp98Xq9BY/HU8C3r4FA4Dt4TXPK5fItuOHmfD4/xI1IJHIuWCqVTqlNms0mabVaIpVKhUCAFIvFT1JbmHw+f2Fh9brURnjpWOrtCggUWGEtNfujJHjUbrcZbHQQeQyHQ9GHMKjoQ2pu2GZD571eL74XrNVqynq4F6QvhdoRz2iHyuizooaVBRuNhjJBHOVYeo/ikREFVLimlaJC+cjValWZIG7o9JBhl8vl5/2loGplgki+DR6hZ3cR70vcAw9BiOZQMSXG/Qka8huWbeMYowAAAABJRU5ErkJggg==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "An example of the header responding to different screen sizes.",
        "title": "An example of the header responding to different screen sizes.",
        "src": "/static/e9b471ea0684582cba81ce61096522d7/fb070/header-usage-3.png",
        "srcSet": ["/static/e9b471ea0684582cba81ce61096522d7/d6747/header-usage-3.png 288w", "/static/e9b471ea0684582cba81ce61096522d7/09548/header-usage-3.png 576w", "/static/e9b471ea0684582cba81ce61096522d7/fb070/header-usage-3.png 1152w", "/static/e9b471ea0684582cba81ce61096522d7/fb104/header-usage-3.png 1728w", "/static/e9b471ea0684582cba81ce61096522d7/8fefe/header-usage-3.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">Header responsive behavior</Caption>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      